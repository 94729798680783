<template>
  <div class="pageMain">
    <div v-if="infoShow">
      <search-head
        :model="searchForm"
        @search="search"
        @reset="reset"
        :isExport="true"
      >
        <el-form :inline="true" :model="searchForm">
          <el-form-item label="设备编号">
            <el-input
              v-model="searchForm.deviceImei"
              size="small"
              placeholder="设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="现场编号">
            <el-input
              v-model="searchForm.deviceNo"
              size="small"
              placeholder="现场编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="searchForm.deviceOnline"
              size="small"
              placeholder="状态"
            >
              <el-option
                v-for="item in deviceOnline"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="机器版本" prop="generation">
              <el-select
                  class="block-select"
                  size="small"
                  clearable
                  v-model="searchForm.generation"
                  placeholder="请选择机器版本"
                >
                  <el-option
                    v-for="item in generationList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
            </el-form-item>
        </el-form>
      </search-head>
      <custom-tables
        :data="tableData"
        :headerCellStyle="headerCellStyle"
        @selectionChange="handleSelectionChange"
        @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
        height="55vh"
        :total="total"
        title="项目设备"
      >
        <!-- <template slot="btns">
          <el-button type="primary" size="small" @click="addFrom">新建</el-button>
        </template> -->
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column prop="deviceType" label="设备类型" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.deviceType == 0">塔吊</span>
              <span v-if="scope.row.deviceType == 1">升降机</span>
              <span v-if="scope.row.deviceType == 2">龙门吊</span>
              <span v-if="scope.row.deviceType == 3">架桥机</span>
              <span v-if="scope.row.deviceType == 4">爬模</span>
              <span v-if="scope.row.deviceType == 5">挂篮</span>
              <span v-if="scope.row.deviceType == 8">扬尘</span>
          <span v-if="scope.row.deviceType == 9">喷淋</span>
        </template>
        </el-table-column>
        <el-table-column prop="deviceImei" align="center" label="设备ID" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="deviceNo" align="center" label="现场编号">
        </el-table-column>
        <el-table-column prop="generation" align="center" label="机器版本">
        </el-table-column>
        <el-table-column prop="deviceOnline" align="center" label="状态">
            <template slot-scope="scope">
          <span v-if="scope.row.deviceOnline == 0" style="color: #008f4d;">在线</span>
          <span v-if="scope.row.deviceOnline == 1" style="color: red;">离线</span>
         
        </template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <span @click="detailItem(scope.row)" class="detail">查看</span>
          </template>
        </el-table-column>
      </custom-tables>
    </div>
    <!-- <monitorAlarm v-if="editShow" :compId="compId"></monitorAlarm> -->
  </div>
</template>

<script>
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import CustomDialog from "../../../components/common/CustomDialog";

import monitor from "../../../api/modules/monitor";
import { equipTypes, deviceOnline,generationList } from "../../../config/dataStatus";
export default {
  name: "index",
  components: { CustomDialog, CustomTables, SearchHead },
  data() {
    return {
      equipTypeList: equipTypes,
      tableData: [],
      searchForm: {
        pageNum: 1,
        pageSize: 10,

      },
      total: 0,
      showDialog: false,
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      editShow: false,
      infoShow: true,
      compId: "",
      deviceOnline:deviceOnline,
      generationList:generationList
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
    
     let params = {
        pageNum:this.searchForm.pageNum,
        pageSize:this.searchForm.pageSize,
        projId:this.$route.query.projId,
        deviceType:this.$route.query.deviceType,
        deviceImei:this.searchForm.deviceImei,
        deviceOnline:this.searchForm.deviceOnline,
        deviceNo:this.searchForm.deviceNo,
        generation:this.searchForm.generation
     }
    
      monitor.getPageProjName(params).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
    addFrom() {
      this.showDialog = true;
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.pageNum = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      this.getList()
    },
    close() {
      this.showDialog = false;
    },
    submit() {
      this.showDialog = false;
    },
    detailItem(item) {
      //详情
      if(item.generation==6){
        this.$router.push({
        path: `/screnDetail`,
        query: {
          url:item.cameChannel,
          type:item.type,
          title:item.deviceImei,
          deviceType:item.deviceType,
          projId:item.projId,
          deviceOnline:item.deviceOnline,
          deviceId:item.deviceId,
          deviceNo:item.deviceNo
        },
      });
      }else if(item.generation==5){
       
        this.$router.push({
        path: `/fiveScren`,
        query: {
          url:item.cameChannel,
          type:item.type,
          title:item.deviceImei,
          deviceType:item.deviceType,
          projId:item.projId,
          deviceOnline:item.deviceOnline,
          deviceId:item.deviceId,
          deviceNo:item.deviceNo
        },
      });
      }else if(item.generation==4){
        this.$router.push({
        path: `/FugitiveScren`,
        query: {
          url:item.cameChannel,
          type:item.type,
          title:item.deviceImei,
          deviceType:item.deviceType,
          projId:item.projId,
          deviceOnline:item.deviceOnline,
          deviceId:item.deviceId,
          deviceNo:item.deviceNo
        },
      });
      }
   
    },
    handleSelectionChange(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped lang="less"></style>
